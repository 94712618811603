import firebase from 'firebase/app';
import 'firebase/database';

const config = {
    apiKey: "AIzaSyCUakb8gBfqejkhuFWPyIzFGaK8Y3qpKJE",
    authDomain: "firevuechat-b3987.firebaseapp.com",
    databaseURL: "https://firevuechat-b3987-default-rtdb.firebaseio.com",
    projectId: "firevuechat-b3987",
    storageBucket: "firevuechat-b3987.appspot.com",
    messagingSenderId: "208830010304",
    appId: "1:208830010304:web:c322ec7243a192e8f6ae25"
}

const db = firebase.initializeApp(config);
export default db;

