<template>
  <login></login>
  <chat></chat>
</template>

<script>
import Login from "./components/login";
import Chat from "./components/chat";

export default {
  components: {Chat, Login},
}
</script>

<style lang="scss">

</style>
